<template>
  <div class="header">
    <h1>{{ this.$t("mypage.mypage") }}</h1>
  </div>
  <div class="summary">
    <div class="item account">
      <img src="@/assets/img/icon_profile.svg" />
      <h2>
        <router-link to="/mypage/account">{{
          this.$t("mypage.account")
        }}</router-link>
      </h2>
    </div>
    <div class="item order">
      <img src="@/assets/img/icon_history.svg" />
      <h2>
        <router-link to="/mypage/order">{{
          this.$t("mypage.order_history")
        }}</router-link>
      </h2>
      <p>
        <span class="count">{{ this.common.number_format(this.ordered) }}</span>
        {{ this.$t("mypage.dashboard_ordered") }} /
        <span class="count">{{ this.common.number_format(this.shipped) }}</span>
        {{ this.$t("mypage.dashboard_shipped") }}
      </p>
    </div>
    <div class="item point">
      <img src="@/assets/img/icon_point.svg" />
      <h2>
        <router-link to="/mypage/point">{{
          this.$t("mypage.point")
        }}</router-link>
      </h2>
      <p>
        {{ this.$t("mypage.dashboard_point_prefix") }}
        <span class="count">{{ this.common.number_format(this.point) }}</span>
        {{ this.$t("mypage.dashboard_point_postfix") }}
      </p>
    </div>
    <div class="item coupon">
      <img src="@/assets/img/icon_coupon.svg" />
      <h2>
        <router-link to="/mypage/coupon">{{
          this.$t("mypage.coupon")
        }}</router-link>
      </h2>
      <p>
        {{ this.$t("mypage.dashboard_coupon_prefix") }}
        <span class="count">{{ this.common.number_format(this.coupon) }}</span>
        {{ this.$t("mypage.dashboard_coupon_postfix") }}
      </p>
    </div>
    <div class="item review">
      <img src="@/assets/img/icon_write.svg" />
      <h2>
        <router-link to="/mypage/review">{{
          this.$t("mypage.review_qna")
        }}</router-link>
      </h2>
      <p>
        {{ this.$t("mypage.dashboard_review_prefix") }}
        <span class="count">{{ this.common.number_format(this.review) }}</span>
        {{ this.$t("mypage.dashboard_review_postfix") }} /
        {{ this.$t("mypage.dashboard_qna_prefix") }}
        <span class="count">{{ this.common.number_format(this.qna) }}</span>
        {{ this.$t("mypage.dashboard_qna_postfix") }}
      </p>
    </div>
    <div class="item nft">
      <img src="@/assets/img/icon_nft.svg" />
      <h2><router-link to="/mypage/nft">NFT</router-link></h2>
      <p>
        You own <span class="count">{{ nft }}</span> NFT(s).
      </p>
    </div>
  </div>

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "Dashboard",
  data: function () {
    return {
      process_queue: [],
      ordered: 0,
      shipped: 0,
      delivered: 0,
      point: 0,
      coupon: 0,
      avatar_score: 0,
      review: 0,
      qna: 0,
      nft: 0,
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    this.load_order_status_data();
    this.load_user_data();
    this.load_coupon_data();
    this.load_review_data();
    this.load_qna_data();
  },
  methods: {
    load_order_status_data: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/order/count/status",
          {
            user_id: self.storage.get_user_id(),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          try {
            let data = response.data.data;

            // 주문 완료 및 상품준비중을 신규주문으로 카운트
            let order_count = parseInt(data.ORDER) + parseInt(data.PREPARE);

            self.ordered = order_count;
            self.shipped = data.SHIPPED;
            self.delivered = data.DELIVERED;
          } catch (error) {
            console.log(error);
          }
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    },

    // 사용자 포인트 정보 가져오기
    load_user_data: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/auth/get",
          {
            user_id: self.storage.get_user_id(),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data.data;
          self.user_point = data.user_point;

          // 기본 주문자 정보 설정
          self.point = data.user_point;
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_user_data

    // 쿠폰 정보 가져오기
    load_coupon_data: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/coupon/list",
          {
            user_id: self.storage.get_user_id(),
            coupon_map_status: "UNUSED",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          try {
            let data = response.data;
            self.coupon = data.length;
          } catch (error) {
            console.log(error);
          }
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_coupon_data

    // 리뷰 정보 가져오기
    load_review_data: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/review/list",
          {
            user_id: self.storage.get_user_id(),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          // 총 갯수 정보 헤더에서 가져오기
          try {
            let str_info = response.headers.wkt_extra_info;
            let extra_info = JSON.parse(str_info);
            self.review = parseInt(extra_info.total_count);
          } catch (e) {
            console.log(e);
          }
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_review_data

    // Q&A 정보 가져오기
    load_qna_data: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/qna/list",
          {
            user_id: self.storage.get_user_id(),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          // 총 갯수 정보 헤더에서 가져오기
          try {
            let str_info = response.headers.wkt_extra_info;
            let extra_info = JSON.parse(str_info);
            self.qna = parseInt(extra_info.total_count);
          } catch (e) {
            console.log(e);
          }
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_qna_data
  },
};
</script>

<style scoped>
.summary {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-items: stretch;
  flex-flow: wrap;

  margin: 1rem 0;
}
.summary > .item {
  width: calc((100% - 1rem - 2px) / 2);
  min-height: 120px;
  padding: 2rem 1rem;
  margin: 0 1rem 1rem 0;
  border: 1px solid #dedede;
  border-radius: 0.4rem;
  text-align: center;
}
.summary > .item:nth-child(2n) {
  margin-right: 0;
}
.summary > .item a {
  text-decoration: none;
  color: #000;
}
.summary > .item a:hover {
  text-decoration: underline;
}
.summary > .item > img {
  display: block;
  height: 3rem;
  margin: 0 auto 1rem auto;
}
.summary > .item > h2 {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0;
  margin: 0 0 1rem 0;
}

.summary > .item > p {
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  color: #808080;
  padding: 0;
  margin: 0;
}
.summary > .item > p > .count {
  color: #000;
  font-weight: 500;
}

@media (max-width: 600px) {
  .summary > .item {
    width: 100%;
    min-height: 120px;
    padding: 2rem 1rem;
    margin: 0 0 1rem 0;
    border: 1px solid #dedede;
    border-radius: 0.4rem;
    text-align: center;
  }
}
</style>
